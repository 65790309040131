<template>
  <div>
    <b-card title="S7 PROTOCOL">
      <b-form @submit.prevent="execute">
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <label for="ip-form" id="ip">IP<span class="text-danger">*</span></label>
              <b-form-input
              id="ip-form"
              required
              v-model="s7Protocol.hardware.ip"
              :disabled="this.s7ProtocolDisabled"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label for="ip-form" id="port">Port<span class="text-danger">*</span></label>
              <b-form-input 
              id="port-form"
              required
              type="number"
              v-model="s7Protocol.hardware.port" :disabled="this.s7ProtocolDisabled"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <label for="rack-form" id="rack">rack<span class="text-danger">*</span></label>
              <b-form-input
              id="rack-form"
              required
              v-model="s7Protocol.rack"
              :disabled="this.s7ProtocolDisabled"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <label for="slot-form" id="slot">slot<span class="text-danger">*</span></label>
              <b-form-input 
              id="slot-form"
              required
              type="number"
              v-model="s7Protocol.slot" :disabled="this.s7ProtocolDisabled"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <label for="action-form" id="action">Action<span class="text-danger">*</span></label>
            <b-form-select id="action-form" v-model="s7Protocol.action" 
            :options="type" :disabled="this.s7ProtocolDisabled" :value="s7Protocol.action === '' ? 'READ' : s7Protocol.action">
            </b-form-select>
          </b-col>
        </b-row>
            <b-row class="mt-1">
              <b-col cols="5">
                <h4>Vars<span v-if="s7Protocol.action=='WRITE'" class="text-danger">*</span></h4>
              </b-col>
              <b-col>
                <b-button @click="addHardwareRow()" variant="info" size="sm" class="ml-1"><b-icon icon="plus" font-scale="1"></b-icon></b-button>
              </b-col>
            </b-row>

            <!-- Solid divider -->
            <b-row>
              <b-col cols="12">
                <hr class="solid">
              </b-col>
            </b-row>

            <!-- Add Hardwares Table <start> -->
            <b-row>
              <b-col cols="12">
                <b-table
                hover
                outlined
                head-variant="light"
                :fields="table_fields_form_s7ProtocolVars" :items="s7ProtocolVarsRows" light responsive="sm">
                <!-- Name Input -->
                <template #cell(type)="row">
                    <b-form-select
                      v-model="s7ProtocolVarsRows[row.index].type"
                      :options="rule_type"
                      :disabled="s7Protocol.action=='WRITE'||s7ProtocolDisabled"
                      required
                    ></b-form-select>
                  </template>

                  <!-- IP Input -->
                  <template #cell(start)="row">
                    <b-form-input
                      id="add-hardware-start-input"
                      type="number"
                      v-model="s7ProtocolVarsRows[row.index].start"
                      required
                    ></b-form-input>
                  </template>

                  <!-- Port Input -->
                  <template #cell(area)="row">
                    <b-form-input
                      id="add-hardware-area-input"
                      type="text"
                      v-model="s7ProtocolVarsRows[row.index].area"
                      required
                    ></b-form-input>
                  </template>

                  <!-- Protocol Input -->
                  <template #cell(dbnr)="row">
                    <b-form-input
                      id="add-hardware-dbnr-input"
                      type="number"
                      v-model="s7ProtocolVarsRows[row.index].dbnr"
                      required
                    ></b-form-input>
                  </template>

                  <!-- Port Input -->
                  <template #cell(value)="row">
                    <b-form-input
                      id="add-hardware-value-input"
                      placeholder="Expected Value: 32 bit signed integer"
                      v-model="s7ProtocolVarsRows[row.index].value"
                      :disabled="s7Protocol.action=='READ'||s7ProtocolDisabled"
                      :required="s7Protocol.action=='WRITE'"
                    ></b-form-input>
                  </template>

                  <template #cell(actions)="row">
                    <!-- Delete Hardware Row Table -->
                    <b-button size="sm" @click="deleteRow(row.index)" variant="danger">
                      <b-icon icon="trash" font-scale="1"></b-icon>
                    </b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- Add Hardwares Table <end> -->
        <b-row class="mt-1">
          <b-col cols="6" class="align-self-end">
            <b-button
              type="submit"
              :disabled="this.s7ProtocolDisabled"
            >
              Execute</b-button>
          </b-col>
        </b-row>
      </b-form>

    </b-card>
    <b-card title="Result" v-if="results.length">
      <b-row>
        <b-col cols="12">
          <b-table
          hover
          outlined
          head-variant="dark"
          :items="results" dark responsive="sm">
        </b-table>
        </b-col>
        <!-- Empty Cols -->
        <b-col cols="8" />
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      s7ProtocolDisabled:false,
      s7Protocol: {
        hardware: {
          ip: '192.168.0.80',
          port: '102',
          protocol: 'S7',
        },
        action: 'READ',
        address: [],
        rack: 0,
        slot: 1,
      },
      results: [],
    }
  },
  created() {
    document.title = 'S7 | Tai sin '
  },
  methods: {
    execute(){
      try {
        this.s7ProtocolDisabled = true;
        if(this.s7Protocol.action == 'READ') {
          this.s7Protocol.address = {
            rack: (typeof this.s7Protocol.rack =="string")?parseInt(this.s7Protocol.rack):this.s7Protocol.rack,
            slot: (typeof this.s7Protocol.slot =="string")?parseInt(this.s7Protocol.slot):this.s7Protocol.slot,
            vars: []
          };
          for(let i=0; i<this.s7ProtocolVarsRows.length; i++){
            this.s7Protocol.address.vars.push({
                type:this.s7ProtocolVarsRows[i].type,
                start: (typeof this.s7ProtocolVarsRows[i].start =="string")?
                  parseInt(this.s7ProtocolVarsRows[i].start):
                  this.s7ProtocolVarsRows[i].start,
                area: (typeof this.s7ProtocolVarsRows[i].area =="string")?
                  parseInt(this.s7ProtocolVarsRows[i].area):
                  this.s7ProtocolVarsRows[i].area,
                dbnr: (typeof this.s7ProtocolVarsRows[i].dbnr =="string")?
                  parseInt(this.s7ProtocolVarsRows[i].dbnr):
                  this.s7ProtocolVarsRows[i].dbnr
            });
          }
          console.info(JSON.stringify(this.s7Protocol.address))
        }else if(this.s7Protocol.action == 'WRITE') {
          this.s7Protocol.address = {
            rack: (typeof this.s7Protocol.rack =="string")?parseInt(this.s7Protocol.rack):this.s7Protocol.rack,
            slot: (typeof this.s7Protocol.slot =="string")?parseInt(this.s7Protocol.slot):this.s7Protocol.slot,
            vars: []
          };
          for(let i=0; i<this.s7ProtocolVarsRows.length; i++){
            this.s7Protocol.address.vars.push({
                value: (typeof this.s7ProtocolVarsRows[i].value =="string")?
                parseInt(this.s7ProtocolVarsRows[i].value):
                this.s7ProtocolVarsRows[i].value,
                start: (typeof this.s7ProtocolVarsRows[i].start =="string")?
                  parseInt(this.s7ProtocolVarsRows[i].start):
                  this.s7ProtocolVarsRows[i].start,
                area: (typeof this.s7ProtocolVarsRows[i].area =="string")?
                  parseInt(this.s7ProtocolVarsRows[i].area):
                  this.s7ProtocolVarsRows[i].area,
                dbnr: (typeof this.s7ProtocolVarsRows[i].dbnr =="string")?
                  parseInt(this.s7ProtocolVarsRows[i].dbnr):
                  this.s7ProtocolVarsRows[i].dbnr
            });
          }
          
        }
        this.$store.dispatch('executorS7/getS7ProtocolStatus', this.s7Protocol)
        .then(successResponse => {
          this.s7ProtocolDisabled = false;
          this.results = this.s7ProtocolResult;
          this.$bvToast.toast(successResponse, {
            title: "Success",
            variant: "success",
            solid: true,
          });
        }).catch(errorResponse => {
          console.info({errorResponse});
          this.s7ProtocolDisabled = false;
          this.$bvToast.toast(errorResponse, {
            title: "Error",
            variant: "warning",
            solid: true,
          });
        });
      } catch (error) {
        console.error(error.stack);
        this.s7ProtocolDisabled = false;
        this.$bvToast.toast(error.message, {
          title: "Error",
          variant: "warning",
          solid: true,
        });
      }
    },
    addHardwareRow(){
        this.s7ProtocolVarsRows.push({type:'INT', start: '', area: '', dbnr: '', value: ''});
    },
    deleteRow(Id, confirmDelete){
      if (this.s7ProtocolVarsRows.length > 1){
        this.s7ProtocolVarsRows.splice(Id, 1);
      }
    },
  },
  computed: {
    type(){
      return ['READ','WRITE']
    },
    table_fields(){
      return [{
          key: "type",
          label: "type",
          filterable: true,
        },{
          key: "start",
          label: "start",
          filterable: true,
        },{
          key: "area",
          label: "area",
          filterable: true,
        },{
          key: "dbnr",
          label: "dbnr",
          filterable: true,
        },{
          key: "value",
          label: "value",
          filterable: true,
        }]
    },
    table_fields_form_s7ProtocolVars(){
      return [...this.table_fields,'actions']
    },
    s7ProtocolVarsRows: {
      get() {
        return this.$store.state.executorS7.s7ProtocolVars;
      },
      set(val) {
        this.$store.state.executorS7.s7ProtocolVars = val;
      }
    },
    rule_type(){
      return [
        "INT",
        "CHAR",
      ]
    },
    s7ProtocolResult() {
      this.$store.state.s7ProtocolStatus = [];
      this.$store.getters['executorS7/s7ProtocolStatus'].map(s7Val => {
        if(s7Val.status !== 200) {
          s7Val.status = `Unsuccessful (${s7Val.status})`;
        }else {
          s7Val.status = `Successful (${s7Val.status})`;
        }
      });
      return this.$store.getters['executorS7/s7ProtocolStatus'];
    }
  },
};
</script>
